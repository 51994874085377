<template>
  <div class="login">
    <div class="title theme-color">
      仅限受邀客户申请开户
      <van-icon class="icon" name="info-o" @click="handleTip" />
    </div>
    <van-cell-group>
      <van-field
        class="phone"
        input-align="right"
        v-model="mobile"
        placeholder="请输入手机号码"
        type="number"
        clearable
        maxlength="11"
        :formatter="formatterMobile"
      >
        <template #left-icon>
          <div class="label" @click="handleShowSheet">
            {{ showLabel }} <van-icon name="arrow-down" />
          </div>
        </template>
      </van-field>
      <van-field v-model="code" placeholder="请输入验证码" type="number" :formatter="formatterCode">
        <template #button>
          <van-button
            size="small"
            type="primary"
            :disabled="!mobile.length || count !== '' || loading"
            @click="validateGetCode"
            style="width: 80px;"
          >
            {{ count === "" ? "发送验证码" : count }}
          </van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-button type="primary" class="btn" @click="login" :disabled="!mobile || !code">
      验证并登录
    </van-button>
    <div></div>

    <div class="message">
      <van-divider>请准备好</van-divider>
      <div class="image-wraper">
        <div class="image" v-for="item in must" :key="item.url">
          <van-image width="100%" height="100%" fit="contain" :src="item.url" />
          <div class="small theme-color">{{ item.title }}</div>
        </div>
      </div>
      <van-divider>可能还需</van-divider>
      <div class="image-wraper">
        <div class="image" v-for="item in need" :key="item.url">
          <van-image width="100%" height="100%" fit="contain" :src="item.url" />
          <div class="small theme-color">{{ item.title }}</div>
        </div>
      </div>
      <van-action-sheet
        v-model:show="showSheet"
        :actions="actions"
        @select="onSelect"
        cancel-text="取消"
        close-on-click-action
      />
    </div>
  </div>
</template>

<script>
import {
  Button,
  Field,
  CellGroup,
  Icon,
  Dialog,
  ActionSheet,
  Divider,
  Image as VanImage
} from "vant";
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import util from "../utils/util";

const must = [
  {
    title: "身份证/护照",
    url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/0-1_IDcard.png"
  },
  {
    title: "住址证明(近三月)",
    url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/0-2_live.png"
  },
  {
    title: "良好网络(WIFI/4G/5G)",
    url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/0-3wifi.png"
  }
];
const need = [
  {
    title: "个人影像",
    url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/0-4_photo.png"
  },
  {
    title: "资产证明",
    url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/0-5_assets.png"
  },
  {
    title: "支票",
    url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/0-6_check.png"
  }
];
const labelNameMap = {
  "+86": "中国大陆 +86",
  "+852": "中国香港 +852",
  "+853": "中国澳门 +853",
  "+886": "中国台湾 +886"
};
const alertMsg = `目前网上开户仅限非美国公民或非美国税务居民的个人用户使用，请先联系客服获取邀请。
美国公民或美国税务居民，需联系客服获取开户方式。
客服电话：xx`;
export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [Divider.name]: Divider,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      showSheet: false,
      dark: false,
      must,
      need,
      count: "",
      loading: false,
      actions: [
        { name: "中国大陆 +86", value: "+86" },
        { name: "中国香港 +852", value: "+852" },
        { name: "中国澳门 +853", value: "+853" },
        { name: "中国台湾 +886", value: "+886" }
      ]
    };
  },
  methods: {
    formatterMobile(value) {
      return this.$util.format_mobile(value);
    },
    formatterCode(value) {
      return this.$util.format_ver_code(value);
    },
    onSelect(item) {
      this.mobileLabel = item.value;
      this.showSheet = false;
    },
    handleShowSheet() {
      this.showSheet = true;
    },
    async validateGetCode() {
      if (this.count !== "") return;
      this.loading = true;
      const res = await this.$api.sendVerCode(`${this.mobileLabel}-${this.mobile}`);
      this.loading = false;
      if (!res) return;
      const { code, msg = "获取验证码失败" } = res;
      if (code === 200) {
        this.$toast("验证码已发送");
        this.count = 60;
        this.begincountDown();
      } else {
        this.$toast(msg);
      }
    },
    begincountDown() {
      this.countDownTimer = setInterval(() => {
        if (this.count <= 0) {
          clearInterval(this.countDownTimer);
          this.countDownTimer = null;
          this.count = "";
        } else {
          this.count -= 1;
        }
      }, 1000);
    },
    async login() {
      const res = await this.$api.login(`${this.mobileLabel}-${this.mobile}`, this.code);
      if (!res) return;
      const { code, msg, data } = res;
      if (code === 200) {
        try {
          this.$toast(msg || "登录成功");
          let path = "/choose/index";
          if (this.$route.fullPath === "/updateRiskTropism" && data.yearlyRiskState === 0) {
            path = "/riskOrientation/step_one";
            this.$store.commit("setIsYearlyRisk", true);
          } else if (data.hasSubmit === 1) {
            path = "/search/index";
          }
          this.$store.commit("setYearlyRiskState", data.yearlyRiskState);
          this.$store.dispatch("handleLogin", () => {
            this.$router.push({ path });
          });
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      } else {
        this.$toast(msg || "登录失败、请重试");
      }
    }
  },
  setup() {
    onMounted(() => {
      util.clearLocalVuex();
      const store = useStore();
      store.commit("setTitleValue", "中诚证券开户/查询");
      store.commit("setStepsShow", false);
      let message = "";
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isWeixin = userAgent.indexOf("micromessenger") !== -1;
      const isDingTalk = userAgent.indexOf("dingtalk") !== -1;
      const isUC = userAgent.indexOf("ucbrowser") !== -1;
      // 如果是PC打开，则提示使用手机打开
      const isPC = util.isPC();
      if (isPC) {
        message = `PC端暂不支持开户\n请使用手机打开该页面`;
      } else if (isWeixin || isDingTalk) {
        message = `${isWeixin ? "微信" : "钉钉"}暂不支持开户\n请使用系统浏览器或者chrome打开该页面`;
      } else if (isUC) {
        message = `UC浏览器暂不支持开户\n请使用系统浏览器或者chrome打开该页面`;
      }
      // 如果有提示消息
      if (message) {
        Dialog.alert({
          title: "说明",
          messageAlign: "center",
          showCancelButton: false,
          showConfirmButton: false,
          message,
          confirmButtonColor: "rgba(0,0,0,0.6)"
        });
      }
    });
    const handleTip = () => {
      Dialog.alert({
        title: "说明",
        messageAlign: "left",
        confirmButtonText: "我知道了",
        message: alertMsg,
        confirmButtonColor: "rgba(0,0,0,0.6)"
      });
    };
    const mobileLabel = ref("+86");
    const showLabel = computed(() => labelNameMap[mobileLabel.value]);
    const mobile = ref("");
    const code = ref("");
    return {
      handleTip,
      mobileLabel,
      showLabel,

      mobile,
      code
    };
  }
};
</script>

<style lang="less">
.login {
  margin-top: 30px;
  .phone {
    .label {
      display: flex;
    }
  }
  .title {
    color: @font-color-1;
    font-size: @font-size-2;
    margin-left: @space-2;
    margin-bottom: @space-4;
    display: flex;
    justify-content: left;
    align-items: center;
    .icon {
      line-height: @font-size-2;
      margin-left: @space-4;
    }
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
  }
  .message {
    padding-left: @space-1;
    padding-right: @space-1;
    .image-wraper {
      display: flex;
      justify-content: space-around;
      margin-bottom: @space-0;
      text-align: center;
      .image {
        width: 25%;
        height: 100%;
      }
      .small {
        font-size: @font-size-3;
      }
    }
  }
}
</style>
